import { Box, InputLabel, Typography, useTheme } from "@mui/material";
import { useIPGeoLocation } from "hooks";
import clsx from "lib/clsx";
import get from "lodash/get";
import { IForm, IPhoneNumberField } from "models/form";
import { MuiTelInput, MuiTelInputCountry } from "mui-tel-input";
import { useEffect, useMemo } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { useDefaultBy } from "./hooks";

const PhoneNumberField = ({
  name,
  label,
  helperText,
  required,
  disabled,
  color,
  size,
  icon,
  styles,
  variant,
  title,
  placeholder,
  defaultByGroups,
  onlyCountries,
}: IPhoneNumberField) => {
  const {
    control,
    resetField,
    formState: { errors },
  } = useFormContext<IForm<string>>();

  const theme = useTheme();

  //#region Set defaultBy
  const defaultByValue = useDefaultBy<string>(defaultByGroups);
  const watchValues = useWatch();
  const currentValue = get(watchValues, name);

  useEffect(() => {
    if (defaultByValue && !currentValue) {
      resetField(name, { defaultValue: defaultByValue, keepDirty: true });
    }
  }, [defaultByValue, currentValue, name, resetField]);
  //#endregion

  const error = get(errors, name);

  const { location } = useIPGeoLocation();

  const defaultCountry = useMemo(
    () =>
      (!onlyCountries?.length
        ? (location.code as MuiTelInputCountry)
        : onlyCountries.some((c) => c === location.code)
          ? (location.code as MuiTelInputCountry)
          : onlyCountries?.[0]) || "AU",
    [location.code, onlyCountries],
  );

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ field }) => (
        <Box className="space-y-4" sx={styles}>
          {label && (
            <InputLabel className="flex text-wrap text-light" htmlFor={name} required={required}>
              <Typography variant="p1">{label}</Typography>
            </InputLabel>
          )}
          <MuiTelInput
            {...field}
            className={clsx("overflow-hidden text-light", variant === "outlined" && "rounded-md")}
            onlyCountries={!!onlyCountries?.length ? onlyCountries : undefined}
            defaultCountry={
              currentValue && currentValue.startsWith("+") && currentValue.length > 3
                ? onlyCountries?.[0] || "AU"
                : defaultCountry
            }
            forceCallingCode
            focusOnSelectCountry
            name={name}
            hiddenLabel
            placeholder={
              !required ? (placeholder ? `${placeholder} (optional)` : "(optional)") : placeholder
            }
            required={required}
            disabled={disabled}
            size={size}
            variant={variant}
            color={color}
            focused={variant === "outlined" || undefined}
            fullWidth
            error={!!error}
            helperText={error?.message || helperText}
            FormHelperTextProps={{
              className: "mx-0 text-inherit",
            }}
            slotProps={{
              input: {
                componentsProps: !label ? { input: { "aria-label": title } } : undefined,
              },
            }}
            MenuProps={{
              classes: {
                list: "p-0",
                paper: "bg-white/50 shadow backdrop-blur border-2 border-solid my-2",
              },
              sx: {
                ".MuiPaper-root": {
                  borderColor: `var(--palette-${color}-main)`,
                },
                ".MuiTelInput-Typography-calling-code": {
                  color: "inherit",
                },
              },
            }}
            sx={{
              "& .MuiInputBase-root": {
                color: "inherit",
                fontWeight: "inherit",
                fontSize: theme.typography.p2.fontSize,
                px: variant !== "standard" ? "10px" : undefined,
              },
              "& .MuiOutlinedInput-root fieldset": {
                borderWidth: "2px",
                borderTopWidth: "3.5px !important",
                borderColor: disabled
                  ? `var(--palette-text-disabled)!important`
                  : `var(--palette-${color}-main)`,
              },
              "& input": {
                py: size === "medium" ? "12.07px" : "7.32px",
                px: variant !== "standard" ? "5px" : undefined,
                overflow: "hidden",
                textOverflow: "ellipsis",
                "&::placeholder": {
                  opacity: 1,
                  color: "var(--palette-primary-main)",
                },
              },
              "& .MuiInputAdornment-root p": {
                font: "inherit",
                color: `text.primary`,
              },
            }}
          />
        </Box>
      )}
    />
  );
};

export default PhoneNumberField;
